import Alert from 'lib/Alert'
import ExpoConstants from 'configs/ExpoConstants'
import Updates from 'lib/Updates'

// prevents undefined === undefined true
const hasSameId = (number1, number2) => {
  const result = number1 && number2 &&
    number1 === number2
  return !!result
}

const confirm = (props)=> {
  Alert.alert(
    props.title,
    props.message,
    props.options,
  )
}

// https://stackoverflow.com/a/47962663/1593212
export const objToQueryString = (obj) => {
  const keyValuePairs = []
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
  }
  return keyValuePairs.join('&')
}

// 0, blank string will be true
const present = (maybe) => {
  if(maybe === undefined || maybe === null){
    return false
  }else{
    return true
  }
}

const jsonToCSV = (array)=> {
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(array[0])
  const csv = [
    header.join(','), // header row first
    ...array.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n')
  return csv
}

const currentVersion = ()=> {
  return `${ExpoConstants.expoConfig?.version}${Updates.updateId ? `_${Updates.updateId.split('-')[0]}` : ''}`
}

export default {
  hasSameId,
  confirm,
  objToQueryString,
  present,
  jsonToCSV,
  currentVersion,
}
