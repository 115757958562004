
export const load = ()=> {
  return (dispatch)=> {}
}

export const show = ()=> {
  return (dispatch, getState)=> {
    const hide_ads = getState().entities?.users?.[getState().auth?.id]?.hide_ads
    if(hide_ads) return

    dispatch({
      type: 'ads/webInterstitial/shown',
      shown: true,
    })
  }
}

export const close = ()=> {
  return (dispatch)=> {
    dispatch({
      type: 'ads/webInterstitial/shown',
      shown: false,
    })
  }
}
