import { sync } from '@/features/sync'
import Alert from '@/lib/Alert'
import { post, get } from '@/features/requests'
import api from '@/api'
import { set as setEntity, schemas } from '@/features/entities'
import analytics from '@/lib/analytics'
import RootNavigation from '@/navigation/RootNavigation'
import { flash } from '@/features/flash'
import { capture } from '@/sentry'
import jwt from '@/utils/jwt'
import { clearGoBackTo } from '@/features/routes'
import i18n, { t } from '@/i18n'
import Updates from '@/lib/Updates'
import { types as draftTypes } from '@/features/drafts'
import Localization from '@/lib/Localization'
import { set as setErrors } from '@/features/errors'
import { set as setRequests } from '@/features/requests'
import { types as accountTypes } from '@/features/accounts'
import { storage } from '@/features/auth/storage'
import { RootState, Dispatch, GetState } from '@/store'
import { Group } from '@/types'
import { capture as captureHook } from '@/utils/error'

// selectors
const userPlan = (state: RootState) => state.entities?.users[state.auth.id]?.subscription?.plan

// this only returns true if the token is expired
// it retuns false if there is no token
export const isExpired = (state: RootState) => {
  const token = state?.auth?.token
  const parsed = jwt.parse(token)
  if(!parsed) return false
  const expAt = parsed.exp * 1000
  const now = Date.now()
  const isExpired = now > expAt

  if(isExpired){
    captureHook(new Error('token expired'), {})
  }

  return isExpired
}

export const selectors = {
  userPlan,
}

export const refreshToken = ({ token }: { token: string }) => {
  return async(dispatch: Dispatch, getState: GetState) => {
    const parsed = jwt.parse(token)
    // FIXME: fix the spec to not mock the jwt
    // @ts-ignore some specs are mocking the jwt, so I can't reutn false
    const id = parsed.slug
    dispatch({ type: types.set, payload: { id, token } })
    await setAuth(({ id, token }))(dispatch, getState)
    // @ts-ignore same as above
    dispatch({ type: accountTypes.prependId, id: parsed.slug })
  }
}

export const setAuth = ({ id, token }: {
  id?: string
  token?: string
})=> {
  return async(dispatch: Dispatch, getState: GetState) => {
    if(id){
      await storage.setId({ id })
    }

    if(token){
      await storage.setToken({ token })
    }

    const accountsDisabled = getState?.()?.devices?.accountsDisabled
    if(id && token && !accountsDisabled){
      await storage.setAuthTokenFor({ id, token })
    }
    return true
  }
}

// it only try to refresh the token.
// it does not do anything if failed to do so.
export const fetchCurrentUser = async(dispatch: Dispatch, getState: GetState)=> {
  try{

    if(!getState().auth.token) return false

    dispatch({ type: types.set, payload: { loading: true } })
    const response = await get(api.users.current())(dispatch, getState)

    const { user, token } = await response?.json?.() || {}

    if(user){
      dispatch(setEntity(user, schemas.user))
      dispatch({ type: 'home/set', home: { groupIds: user.groups?.map((g: Group) => g.id) } })
    }
    if(token){
      await refreshToken({ token })(dispatch, getState)
    }

    return true
  }catch(err){
    ppError(err)
    capture(err)
  }finally{
    dispatch({ type: types.set, payload: { loading: false } })
  }
}

export const loginAs = ({
  id
}: {
  id: string
}) => {
  return async(dispatch: Dispatch, getState: GetState) => {
    // get token from SecureStorage
    const token = await storage.getAuthTokenFor({ id })

    // when failed to load token from secure storage.
    if(!token) {
      await storage.removeAuth({ id })
      dispatch({ type: accountTypes.removeId, id })
      RootNavigation.resetTo('Home')
      return
    }

    // refresh to keep token and user id in sync with store
    await refreshToken({ token })(dispatch, getState)
    // refresh user related data by fetching current user
    await fetchCurrentUser(dispatch, getState)
    dispatch({ type: accountTypes.prependId, id })
    dispatch({ type: 'drafts/clear' })
    RootNavigation.resetTo('Home')
  }
}

// login will store user and token from given data
// when only token is given, it will fetch data from api
// it is used after signup and handling auth link from email login
export const login = async({
  data,
  dispatch,
  getState,
}: {
  data: { token: string },
  dispatch: Dispatch,
  getState: GetState,
}) => {
  try{
    let { token } = data

    // check if the token is expired
    if(token && !jwt.isValid(token)) {
      flash.add({ type: 'error', body: t('errors.user.tokenExpired') })(dispatch)
      return { ok: false }
    }

    // set token for authentication
    await refreshToken({ token })(dispatch, getState)
    // fetch user detail from api
    const loggedIn = await fetchCurrentUser(dispatch, getState)
    if(!loggedIn) return { ok: false }// failed to login

    // navigate user if routes.goBackTo exists
    const goBackTo = getState().routes?.goBackTo
    if(goBackTo?.name){
      RootNavigation.navigate(goBackTo.name, goBackTo.params)
      // clear after navigation
      clearGoBackTo()(dispatch)
    }else{
      // or navigate to Home by default
      RootNavigation.reset({ index: 0, routes: [{ name: 'Home' }], })
    }

    flash.add({ type: 'success', body: t('flash.loggedIn') })(dispatch)

    return { ok: true }
  }catch(err){
    ppError(err)
  }
}

export const signUp = ({
  payload,
  onSuccess,
  onFailure,
}: {
  payload: any,
  onSuccess?: (data: any) => void,
  onFailure?: (data: any) => void,
}) => {
  return async(dispatch: Dispatch, getState: GetState) => {
    // We used to count sign_up as a conversion, but we only count for new
    // group creating as a conversion (since 2022-11-28) see NewGroupScreen

    const locale = i18n.locale
    const time_zone = Localization.timezone
    const response = await sync.create({
      entity: 'user',
      payload: {
        ...payload,
        user: {
          ...payload?.user,
          locale,
          time_zone,
        },
      },
      options: {
        onSuccess,
        onFailure,
      }
    })(dispatch, getState)
    if(response?.ok){
      const data = await response.json()
      await login({ data, dispatch, getState })
    }
    return response
  }
}

export const signIn = ({
  email,
  password,
  account_name,
  provider,
  accessToken,
  userId,
}: {
  email?: string,
  password?: string,
  account_name?: string,
  provider?: string,
  accessToken?: string,
  userId?: string,
}) => {
  return async(dispatch: Dispatch, getState: GetState) => {
    const response = await post(
      api.auth.login(),
      { email, password, account_name, provider, accessToken, userId, }
    )(dispatch, getState)
    if(response?.ok){
      analytics().logEvent('login', { method: 'password' })
      const data = await response.json()
      await login({ data, dispatch, getState })
    }else{
      if(response?.status === 401) {
        flash.add({ type: 'error', body: t(`errors.status.401`) })(dispatch)
      }
      const data = await response?.json?.() || {}
      if(data.errors){
        pp(data.errors)
        dispatch(setErrors(data.errors))
      }
    }
    return response
  }
}

// it will send a email to the given email address
// login process will be handled with LinkHandler
export const emailSignIn = ({
  email
}: {
  email: string,
}) => {
  return async(dispatch: Dispatch, getState: GetState) => {
    analytics().logEvent('login', { method: 'email' })
    return await post(
      api.auth.create(),
      { email }
    )(dispatch, getState)
  }
}

export const signOutAll = ()=> {
  return async(dispatch: Dispatch, getState: GetState) => {
    const accounts = getState()?.accounts?.ids
    const promises: any[] = []
    accounts.map((id: string) => {
      promises.push(storage.removeAuth({ id }))
    })
    await Promise.all(promises)
    dispatch({ type: 'accounts/clearIds' })
    await signOut()(dispatch, getState)
  }
}

export const signOut = ()=> {
  return async(dispatch: Dispatch, getState: GetState) => {
    const authId = getState()?.auth?.id
    dispatch({ type: accountTypes.removeId, id: authId })
    await storage.removeAuth({ id: authId })
    dispatch({ type: types.clear, })
    dispatch({ type: 'entities/purge' })
    dispatch({ type: draftTypes.clear })
    setRequests({ lastRequestAt: null })(dispatch)
    const accounts = getState()?.accounts?.ids
    if(accounts?.length > 0){ // if any accounts are remaining
      const nextId = accounts[0]
      await loginAs({ id: nextId })(dispatch, getState)
    }
  }
}

export const signOutAndNavigate = ()=> {
  return async(dispatch: Dispatch, getState: GetState) => {
    await signOut()(dispatch, getState)
    RootNavigation.resetToLanding()
  }
}

// You cannot use persistor.purge() here.
// It will break the default exports of auth reducer
export const handlePurge = ()=> {
  return async(dispatch: Dispatch, getState: GetState) => {
    dispatch({
      type: 'RESET',
      state: { // you need to give _persist object otherwise it will not update the persited object
        _persist: {
          version: 1, rehydrated: true
        }
      }
    })

    await storage.removeI18n()
    await signOutAndNavigate()(dispatch, getState)
    setTimeout(async()=> {
      await Updates.reloadAsync()
    }, 500) // wait for persistor to run
  }
}

// delete all store
export const purge = () => {
  return async(dispatch: Dispatch, getState: GetState) => {
    Alert.alert(
      t('general.confirm'),
      t('alerts.purge.description'),
      [
        { text: t('alerts.purge.ok'), style: 'destructive', onPress: async()=> { await handlePurge()(dispatch, getState) }},
        { text: t('general.cancel'), style: 'cancel', onPress: ()=> {}},
      ]
    )
  }
}

type SetAction = {
  type: 'auth/set',
  payload: any,
}
type ClearAction = {
  type: 'auth/clear',
}
type Action = SetAction | ClearAction

export const types = {
  set: 'auth/set',
  clear: 'auth/clear',
} as const

type State = {
  id: string | null
  token: string | null
  loading?: boolean | null
}

const initialState: State = {
  id: null,
  token: null,
  loading: null,
}

// auth is not persisted for security reasons.
export default (state = initialState, action: Action) => {
  switch (action.type) {
    case types.set: {
      return{
        ...state,
        ...action.payload
      }
    }
    case types.clear: {
      return initialState
    }
    default: {
      return state
    }
  }
}
